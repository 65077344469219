<script setup>
  import MatriculaService from '@/services/matricula.service.js'
</script>
<template>
  <section :class="['appCarteirinha', status]">
    <div>
      <header v-if="ready">
        <div class="pic" :style="{ backgroundImage: `url('${ aluno.foto }')` }">
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 430 493.7">
          <path class="st0" :fill="schema[status]" d="M340.9,254.7c22-.7,44-1.4,66-2.2,7.7-.3,15.4-.6,23.1-.9V81.3c-8.8-.3-17.6-.5-26.3-.9-18.9-.8-37.7-1.7-56.6-2.6-10-.5-15.2-7-13.2-16.7,1.6-7.7,3.5-15.4,5.4-23.1,3.2-12.7,6.5-25.4,9.7-38h-176.5c-1.4,4.5-3,9-4.6,13.4-2.2,6.3-6.8,9.9-12.7,9.5-2.6-.2-5.1-1-7.3-2.2-11.3-6.1-22.4-12.4-33.5-18.8-1.1-.6-2.2-1.3-3.3-1.9H0v134.1c19.7,13.3,39.3,26.5,59,39.8,1.1.7,2.1,1.4,3.1,2.3,2.1,1.9,2.2,3.2.4,5.3-1.1,1.1-2.2,2.2-3.4,3.1-19.3,16.5-39,32.6-59,48.1v170.9c6.6,2.1,13.5,3.4,20.9,3.9,19.1,1.3,36.4-4,51.7-15.6,22.6-17.2,45.3-34.4,67.9-51.5,4.9-3.7,9.9-7.2,14.8-10.8,7.2-5.4,18.4-2.3,21.7,6.4,1.3,3.3,2.7,6.4,4,9.7,11.1,29,22.1,57.9,33.1,87,12.8,33.8,46.4,51.4,77.2,50.5,13.9-.4,26.9-4.4,39.2-10.3,29.3-14,45.4-37.3,48-69.7,1-12-1.6-23.5-6-34.6-13.9-34.7-26.3-69.9-37.4-105.6-2.1-6.9-1.5-7.9,5.8-8.1Z"/>
        </svg>
        <h1>{{ aluno.nome }}</h1>
      </header>
      <div v-if="ready">
        <div v-if="status == 'semmatricula'">
          <h2>Sem Matrícula</h2>
          <p>Aluno sem matrícula regular para
            o período vigente.</p>
        </div>
        <template v-else>
          <div>
            <h2>{{ aluno.turma }}</h2>
            <p>{{ aluno.serie }} - {{ aluno.curso }}</p>
          </div>
          <ul>
            <li>
              <label :style="{ color: schema[status] }">Matrícula</label>
              <p>{{ aluno.rm }}</p>
            </li>
            <li>
              <label :style="{ color: schema[status] }">Nascimento</label>
              <p>{{ aluno.nascimento }}</p>
            </li>
          </ul>
        </template>
      </div>
      <footer v-if="ready">
        <img :src="require('@/assets/img/thumb.png')" width="30" />
        <p v-if="year">{{ year }}</p>
      </footer>
    </div>    
  </section>  
</template>

<script>
export default {
  name: 'Carteirinha Aluno',
  props: {
    idPessoa: {
      type: [String, Number]
    }
  },
  data () {
    return {
      aluno: null,
      ready: false,
      year: null,
      schema: {
        vermelho: '#FE8085',
        verde: '#1FE188',
        azul: '#1DCDED',
        semmatricula: '#E3E3E9',
        semficha: '#E3E3E9',
      },
      status: 'semmatricula'
    }
  },
  mounted () {
    this.getAluno()
  },
  methods: {
    getAluno () {
      MatriculaService.getPessoaCentralAluno( this.idPessoa, 0 ).then(
        response => {
          console.log("getPessoaCentralAluno:", response.data)
          if ( Object.keys(response.data).length > 0 ) {
            this.alunoFull = response.data.dados
            
            const base = response.data.dados
            this.aluno = {
              id: base.Aluno.idAluno,
              nome: base.Basicos.nome.toLowerCase(),
              rm: base.Aluno.rm,
              nascimento: base.Basicos ? base.Basicos.dataNascimento : "-",
              rg: base.Documento ? base.Documento.rg : "-",
              cpf: base.Documento ? base.Documento.cpf : "-",
              email: base.Aluno ? base.Aluno.emailAcademico : "-",
              genero: base.Basicos ? base.Basicos.sexo : "-",
              foto: base.Basicos.urlFoto
            }

            this.getMatriculas(base)
            
        }
        },
        () => {

        }
      )
    },
    getMatriculas ( options ) {
      const { idAluno, idTurma, idSerie, idMatricula } = options.Aluno
      const data = {
        idAluno, 
        idTurma,
        idSerie
      }

      MatriculaService.getMatriculas( data ).then(
        response => {
          const currentYear = new Date().getFullYear()

          this.year = currentYear

          const reg = response.data.filter( a => a.situacaoAtivo && a.siglaTipoCurso == 'R' && a.nomePeridoletivo == currentYear)
          
          if ( reg.length == 0 ) {
            this.status = 'semmatricula'
          } else {
            this.status = 'azul'
            const matricula = reg[0]
            const aluno = {
              ...this.aluno,
              serie: matricula.nomeSerie,
              curso: matricula.nomeCurso,
              turma: matricula.nomeTurma,
              periodoLetivo: matricula.nomePeridoletivo,
            }

            this.aluno = aluno
          }

          this.getFichaSaida(idMatricula)
          
        },
        () => {

        }
      )
    },
    getFichaSaida ( idMatricula ) {
      MatriculaService.getFichaSaida( idMatricula ).then(
        response => {
          const hasFicha = Object.keys(response.data).length > 0
          this.status = hasFicha ? response.data.autorizacaoSaida.corAutorizacao.toLowerCase() : 'semficha' 
          
          console.log('getFichaSaida:', response)
          
          this.ready = true
        },
        () => {

        }
      )
    },
  }
}
</script>

<style lang="scss" scoped>
  .appCarteirinha {
    background-color: $color-bg;
    color: $color-dev;

    >div {
      display: flex;
      flex-direction: column;
      align-content: stretch;
      max-width: 440px;
      margin: $mg auto 0;
      border-radius: $border-radius;
      overflow-y: hidden;
      background-color: $color-secondary;
      height: 90vh;

      @media screen and ( max-width: 689px ) {
        margin: 0; height: auto; min-height: 100vh;
        border-radius: 0;
      }

      @media screen and ( max-height: 650px ) {
        overflow-y: auto;
      }

      header {
        display: block;
        width: 100%;
        position: relative;

        .pic {
          width: 220px; height: 220px;
          border: 16px solid $color-secondary;
          border-radius: 100%;
          position: absolute;
          top: 12%;
          left: 0; right: 0;
          margin: auto; 
          z-index: 11;
          overflow: hidden;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: $color-bg
        }

        svg {
          position: relative;
          z-index: 10;
        }

        h1 {
          text-align: center;
          position: absolute;
          bottom: $mg;
          left: 0; right: 0;
          margin: auto;
          padding: 0 $mg;
          z-index: 12;
          text-transform: capitalize;
          font-size: 3.5rem;
        }
      }

      >div {
        padding: $hmg_mid $mg;
        flex: 1;
        display: flex; flex-direction: column;
        justify-content: center;

        >div {
          text-align: center
        }
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0; margin: $mg 0 0 0;

          li {
            list-style: none;
            padding: 0; margin: 0;

            &:last-child {
              text-align: right
            }

            p {
              font-size: 1.5rem;
            }
            label {
              font-weight: bold;
            }
          }
        }
      }

      footer {
        padding: 0 $mg $mg $mg;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
</style>