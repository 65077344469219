<template>
  <section>
    <div ref="produtoForm">
      <div class="row no-padd">
        <div class="col-md-6 form-group no-padd-left">
          <label>Produto</label>
          <input-control
            v-model="produto.descricao" 
            placeholder="Ex. Camiseta Babylook 100% Algodão Gola Simples" 
            name="descricao"
            type="text"
            :valid="valid.descricao" 
            @keyup="valid.descricao = true"
            required
            maxlength="80"
          />
        </div>
        <div class="col-md-6 form-group no-padd-left">
          <label>Descrição loja</label>
          <input-control
            v-model="produto.descricaovenda" 
            placeholder="Ex. Camiseta Babylook" 
            name="descricaovenda"
            type="text"
            :valid="valid.descricaovenda" 
            @keyup="valid.descricaovenda = true"
            required
          />
        </div>
        <div class="col-md-12 form-group no-padd-left">
          <label>Detalhes</label>
          <textarea-control
            v-model="produto.detalhes" 
            placeholder="Descreva os detalhes deste produto" 
            name="detalhes"
            type="text"
            :valid="valid.detalhes" 
            @keyup="valid.detalhes = true"
            size="large"
            resize
            required
          />
        </div>
      </div>

      <div class="row no-padd" >
        <div class="col-md-3 form-group no-padd-left">
          <label>NCM</label>
          <input-control
            v-model="produto.ncm" 
            placeholder="3209482394" 
            name="ncm"
            type="text"
            :valid="valid.ncm" 
            @keyup="valid.ncm = true"
          />
        </div>
      </div>

      <div class="row no-padd" >
        <div class="col-md-4 form-group no-padd-left">
          <label>Início venda</label>
          <input-control
            v-model="produto.iniciovenda" 
            placeholder="DD/MM/AAAA" 
            name="iniciovenda"
            type="date"
            :valid="valid.iniciovenda" 
            @keyup="valid.iniciovenda = true"
            required
          />
        </div>
        <div class="col-md-4 form-group no-padd-left">
          <label>Término venda</label>
          <input-control
            v-model="produto.terminovenda" 
            placeholder="DD/MM/AAAA" 
            name="terminovenda"
            type="date"
            :valid="valid.terminovenda" 
            @keyup="valid.terminovenda = true"
            required
          />
        </div>
      </div>

      <div class="row no-padd" >
        <div class="col-md-4 form-group no-padd-left">
          <label>Preço custo</label>
          <input-control
            v-model="produto.PrecoCusto" 
            placeholder="99,99" 
            name="PrecoCusto"
            type="number"
            :valid="valid.PrecoCusto" 
            @keyup="valid.PrecoCusto = true"
            required
          />
        </div>
        <div class="col-md-4 form-group no-padd-left">
          <label>Preço venda</label>
          <input-control
            v-model="produto.PrecoVenda" 
            placeholder="99,99" 
            name="PrecoVenda"
            type="number"
            :valid="valid.PrecoVenda" 
            @keyup="valid.PrecoVenda = true"
            required
          />
        </div>
      </div>

      <section class="rulesCard">
        <card>
          <div class="card-header">
            <h5>Regras de Negócio</h5>
          </div>
          <div class="rules">
            <div>
              <h6>Geral</h6>
              <div class="form-group">
                <label>Precifica por item</label>
                <div class="switchWrapMini">
                  <switch-control v-model:checked="produto.precoitem" labelOn="Sim" labelOff="Não"></switch-control>
                </div>
              </div>
              <div class="form-group">
                <label>Controla estoque</label>
                <div class="switchWrapMini">
                  <switch-control v-model:checked="produto.controlaestoque" labelOn="Sim" labelOff="Não"></switch-control>
                </div>
              </div>
            </div>
            <div>
              <h6>Matrículas</h6>
              <div class="form-group">
                <label>Gera matricula</label>
                <div class="switchWrapMini">
                  <switch-control v-model:checked="produto.servicoextra" labelOn="Sim" labelOff="Não"></switch-control>
                </div>
              </div>
              <template v-if="produto.servicoextra">
                <div class="row no-padd" ref="produtoForm">
                  <div class="col-md-6 form-group no-padd-left">
                    <label>Quantidade de turmas</label>
                      <input-control
                        v-model="produto.numeroturmas" 
                        placeholder="99" 
                        name="numeroturmas"
                        type="number"
                        :valid="valid.numeroturmas"
                      />
                  </div>
                </div>
                <div class="row no-padd" ref="produtoForm">
                  <div class="col-md-4 form-group no-padd-left">
                    <label>Início matrículas</label>
                    <input-control
                      v-model="produto.matriculainicial" 
                      placeholder="DD/MM/AAAA" 
                      name="matriculainicial"
                      type="date"
                      :valid="valid.matriculainicial" 
                    />
                  </div>
                  <div class="col-md-4 form-group no-padd-left">
                    <label>Término matrículas</label>
                    <input-control
                      v-model="produto.matriculafinal" 
                      placeholder="DD/MM/AAAA" 
                      name="matriculafinal"
                      type="date"
                      :valid="valid.matriculafinal"
                    />
                  </div>
                </div>
              </template>
            </div>
            <div>
              <h6>Gestão</h6>
              <div class="form-group">
                <label>Não comunica venda</label>
                <div class="switchWrapMini">
                  <switch-control v-model:checked="produto.nao_comunica_venda" labelOn="Não comunica" labelOff="Comunica"></switch-control>
                </div>
              </div>
            </div>
          </div>
        </card>
      </section>
      <footer class="action-wrap">
        <a href="#" class="btn btn-primary" @click.prevent="save()">
          Salvar
        </a>
        <a href="#" class="btn btn-secondary" @click.prevent="cancel()">
          Cancelar
        </a>
      </footer>
    </div>
    <div>
      <div class="activityBox">
        <div>
          <div class="switchWrap form-group">
            <switch-control v-model:checked="produto.ativo" labelOn="Produto Ativo" labelOff="Produto Inativo"></switch-control>
          </div>
        </div>
        <div>
          
        </div>
      </div>
      <template v-if="idProduto">
        <imagem-produto :produto="produto" @uploaded="updateImage"></imagem-produto>
        <subcategorias-produto v-if="subcategorias" :scope="subcategorias" @select="updateSubs"></subcategorias-produto>
        <formas-pagamento-produto v-if="formasPagamento" :scope="formasPagamento" @select="updatePags"></formas-pagamento-produto>
      </template>
    </div>
    
  </section>
</template>

<script>
import MarketplaceService from '@/services/marketplace.service.js'
import FormService from '@/services/form.service.js'
import { formatDate, unformatDate } from '@/services/utils.service.js'
import ImagemProduto from '@/components/marketplace/parametrizacao/utilities/ImagemProduto.vue'
import SubcategoriasProduto from '@/components/marketplace/parametrizacao/form/SubcategoriasProduto.vue'
import FormasPagamentoProduto from '@/components/marketplace/parametrizacao/form/FormasPagamentoProduto.vue'

export default {
  components: {
    ImagemProduto,
    SubcategoriasProduto,
    FormasPagamentoProduto
  },
  props: {
    scope: {
      type: Object
    }
  },
  data () {
    return {
      produto: {},
      valid: {
        descricao: true,
        PrecoCusto: true,
        PrecoVenda: true,
        detalhes: true,
        iniciovenda: true,
        terminovenda: true,
        descricaovenda: true,
        ncm: true,
      },
      idProduto: null,
      subcategorias: [],
      formasPagamento: [],
      saved: {
        produto: false,
        subsPags: false
      }
    }
  },
  mounted () {
    this.produto = this.scope
    this.idProduto = this.produto.idtipomaterial
    this.getSubsEPags()
    this.unformatDates()
  },
  methods: {
    getSubsEPags () {
      window.spinner.emit("open")

      MarketplaceService.getSubcategoriasEFormasPagamento( this.idProduto ).then(
        response => {
          // console.log("getSubcategoriasEFormasPagamento:", response)
          if ( response.status == 200 ) {
            this.subcategorias = response.data.subCategoriasNovas
            this.formasPagamento = response.data.formasPagamentosNovas
          }
          
          window.spinner.emit("close")
        },
        () => {
          window.spinner.emit("close")
        }
      )
    },
    formatDates () {
      if ( this.produto.iniciovenda ) this.produto.iniciovenda = formatDate(this.produto.iniciovenda)
      if ( this.produto.terminovenda ) this.produto.terminovenda = formatDate(this.produto.terminovenda)
      if ( this.produto.matriculainicial ) this.produto.matriculainicial = formatDate(this.produto.matriculainicial)
      if ( this.produto.matriculafinal ) this.produto.matriculafinal = formatDate(this.produto.matriculafinal)
    },
    unformatDates () {
      if ( this.produto.iniciovenda ) this.produto.iniciovenda = unformatDate(this.produto.iniciovenda)
      if ( this.produto.terminovenda ) this.produto.terminovenda = unformatDate(this.produto.terminovenda)
      if ( this.produto.matriculainicial ) this.produto.matriculainicial = unformatDate(this.produto.matriculainicial)
      if ( this.produto.matriculafinal ) this.produto.matriculafinal = unformatDate(this.produto.matriculafinal)
    },
    save () {
      const valid = FormService.validate(this.$refs.produtoForm, this.valid)
      console.log("valid:", valid)

      if ( valid.response ) {
        this.formatDates()
        
        // console.log("iniciovenda:", this.produto.iniciovenda)
        // console.log("terminovenda:", this.produto.terminovenda)

        window.spinner.emit("open")
        MarketplaceService.saveTipoMaterial( this.produto ).then(
          response => {
            console.log("response:", response)
            if ( response.status == 200 ) {
              const action = this.idProduto ? 'atualizado' : 'salvo'
              this.unformatDates()

              window.toaster.emit('open', {style: "success", message: "Produto " + action + " com sucesso", floater: true})
              
              this.saved.produto = true

              if ( !this.idProduto ) { 
                this.idProduto = response.data.tipoMaterial.idtipomaterial
                this.saved.subsPags = true
                this.getSubsEPags()
              }

              this.trackSaveFinish(response.data.tipoMaterial)
            }
            // window.spinner.emit("close")
          },
          error => {
            console.log("error:", error)
            window.spinner.emit("close")
            window.toaster.emit('open', {style: "warning", message: "Ops! Algo aconteceu. Não foi possível salvar este produto. Tente novamente, se o problema persistir, informe o suporte.", floater: true})
          }
        )

        if ( this.idProduto ) {
          const data = this.buildSubsPagsData()
          console.log("data:", data)

          MarketplaceService.saveSubcategoriasEFormasPagamento( data ).then(
            response => {
              console.log("response:", response)
              if ( response.status == 200 ) {
                this.saved.subsPags = true
                this.trackSaveFinish()
              }
            },
            () => {
              window.toaster.emit('open', {style: "warning", message: "Ops! Algo aconteceu. Não foi possível salvar as formas de pagamento ou subcategorias deste produto. Tente novamente, se o problema persistir, informe o suporte.", floater: true})
            }
          )
        }
        
      }
      // console.log("tipomaterial:", this.produto.idtipomaterial)
      
    },
    buildSubsPagsData () {
      const formaPagamento = []
      this.formasPagamento.map( k => {
        if ( k.selected ) formaPagamento.push(k.idformapagamentomaterial)
      })

      const subCategorias = []
      this.subcategorias.map( k => {
        if ( k.selected ) subCategorias.push(k.idsubcategorialoja)
      })

      const data = {
        idtipomaterial: this.idProduto,
        formaPagamento: formaPagamento,
        subCategorias: subCategorias
      }

      return data
    },
    trackSaveFinish( produto ) {
      const finished = !Object.values(this.saved).some( a => !a)
      if ( finished ) {

        window.spinner.emit('close')
        if ( produto ) {
          this.$emit('onSave', produto)
        }
      }
    },
    cancel () {
      this.$emit("onClose")
    },
    updateImage ( e ) {
      this.produto.imgproduto = e.imgproduto
    },
    updateSubs ( e ) {
      this.subcategorias = e
    },
    updatePags ( e ) {
      this.formasPagamento = e
    }
  }
}
</script>

<style lang="scss" scoped>
  section {
    display: flex;
    align-items: flex-start;
    align-content: stretch;

    > div {
      flex: 1;
      &:first-child{
        margin-right: $mg;
      }
      &:last-child {
        max-width: 270px;
      }
    }
  }

  .activityBox {
    display: flex; flex-direction: column;
    align-content: stretch; align-items: stretch;
    background-color: $color-secondary;
    width: 100%; max-width: 270px;
    border-radius: $border-radius;
    overflow: hidden;

    >div {
      &:first-child {
        background-color: $color-fade;
      }
      // &:last-child {
      //   background-color: $color-secondary;
      //   padding: $hmg_small;
      // }
    }
  }

  .switchWrap {
    margin: 0 !important
  }

  .card-header {
    border-bottom: 1px solid $color-bg;
    padding: $hmg_mid
  }

  .rulesCard {
    margin-top: $mg;
    width: 100%;

    > section {
      width: 100%;
    }
  }
  .rules {
    display: flex; align-items: stretch; align-content: stretch;
    padding: $hmg_mid;

    > div {
      flex: 1;

      >h6 {
        padding-left: $hmg_small
      }
    }
  }

  footer {
    display: flex;
    align-items: center; align-content: center;
    justify-content: flex-end;

    a {
      margin-left: $hmg_small
    }
  }

</style>